import { useCallback } from "react";
import {
    generatePath as _generatePath,
    NavigateOptions,
    useNavigate as _useNavigate,
    useParams as _useParams,
    useSearchParams as _useSearchParams,
} from "react-router-dom";
import { SafeParseError, z } from "zod";

import { routes } from "routes/routes";
import { Route, RouteAllParams, RouteName, RouteSubRoute } from "routes/routes.types";

export class RouteParamsValidationError extends Error {
    constructor() {
        super("Route parameters validation error");
    }
}

export const routeNames = Object.keys(routes) as RouteName[];

type GeneratePathRouteParamsOptions<T extends Route> = RouteAllParams<T>;
export type GeneratePathOptions<T extends Route> = GeneratePathRouteParamsOptions<T> & RouteSubRoute<T>;
export const generatePath = <const T extends Route>(
    route: T,
    options: GeneratePathOptions<T> | undefined = undefined
) => {
    const { paramsSchema, searchParamsSchema, subRoutes } = route;

    try {
        let path: string;
        if (paramsSchema) {
            const paramParseResult = paramsSchema.safeParse(options?.params ?? {});
            if (paramParseResult.success) {
                path = _generatePath(route.path, paramParseResult.data);
            } else {
                // Don't generate path if the path params are incorrect
                console.error(
                    "Error generating path",
                    (paramParseResult as SafeParseError<unknown>).error.flatten().fieldErrors
                );
                return undefined;
            }
        } else {
            path = _generatePath(route.path);
        }

        if (subRoutes && options?.subRoute) {
            const { path: subRoutePath, subRoutes: subSubRoutes } = options.subRoute;
            path = `${path}/${subRoutePath}`;

            if (subSubRoutes && options?.subSubRoute) {
                const { path: subSubRoutePath } = options.subSubRoute;
                path = `${path}/${subSubRoutePath}`;
            }
        }

        let query = "";
        if (searchParamsSchema) {
            const searchParams = searchParamsSchema.parse(options?.searchParams ?? {});
            const definedSearchParams = Object.keys(searchParams).reduce((acc, key) => {
                const value = searchParams[key];
                if (value === undefined || value === null || value === "") {
                    return acc;
                }
                return { ...acc, [key]: searchParams[key] };
            }, {});
            const urlSearchParams = new URLSearchParams(definedSearchParams);
            query = `?${urlSearchParams.toString()}`;
        }

        const pathWithQuery = `${path}${query}`;
        return pathWithQuery;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        throw new RouteParamsValidationError();
    }
};

/**
 * Generate Route path and naviagte in one function
 */
export const useNavigate = () => {
    const _navigate = _useNavigate();

    const navigate = useCallback(
        <const T extends Route>(
            route: T,
            options: (GeneratePathOptions<T> & NavigateOptions) | undefined = undefined
        ) => {
            const path = generatePath(route, options);
            if (!path) return;

            _navigate(path, options);
        },
        [_navigate]
    );

    return navigate;
};

export const useParams = <const T extends Route & { paramsSchema: NonNullable<Route["paramsSchema"]> }>(
    route: T
): z.infer<T["paramsSchema"]> => {
    const params = _useParams();
    const parsedParams = route.paramsSchema.parse(params);
    return parsedParams;
};

/**
 * Behaves like `useState`, but stores data in the URL search params
 *
 * @param route
 * @param searchParamKey
 */
export const useSearchParam = <
    const T extends Route & { searchParamsSchema: NonNullable<Route["searchParamsSchema"]> },
    TKey extends keyof z.infer<T["searchParamsSchema"]>,
>(
    route: T,
    searchParamKey: TKey
) => {
    const [searchParams, setSearchParams] = _useSearchParams();

    const searchParam = searchParams.get(searchParamKey as string) ?? "";
    const parsedSearchParams = route.searchParamsSchema.shape[searchParamKey].parse(searchParam);

    const setSearchParam = useCallback(
        (value: z.infer<T["searchParamsSchema"]>[TKey] | null) => {
            if (value === undefined || value === null || value === "") {
                searchParams.delete(searchParamKey as string);
            } else {
                searchParams.set(searchParamKey as string, value);
            }

            setSearchParams(searchParams, { replace: true });
        },
        [searchParamKey, searchParams, setSearchParams]
    );

    return [parsedSearchParams, setSearchParam] as const;
};

// Helpers

// TODO Improve the data source returns, so this wont be needed
export const toDeviceTypeParam = (DevicePageType: any) => {
    if (DevicePageType === "Compressor") {
        return "compressors" as const;
    } else if (DevicePageType === "Astronaut") {
        return "astronauts" as const;
    } else if (DevicePageType === "Juno") {
        return "juno" as const;
    } else if (DevicePageType === "Discovery") {
        return "discovery" as const;
    } else if (DevicePageType === "Other") {
        return "other" as const;
    } else {
        return undefined;
    }
};
