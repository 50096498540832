import { forwardRef } from "react";
import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";

import { ComboboxMenuItem } from "components/ui/Combobox/ComboboxMenu/ComboboxMenuItem";
import { ComboboxItemWithAllItem, ComboboxOptions, ItemValue } from "components/ui/Combobox/combobox.types";
import { OUTSIDE_CLICK_NO_CLOSE_CLASS } from "hooks/useOutsideClick";

const ComboboxMenuStyled = styled.div`
    padding: 0.25rem 0;
`;

const ComboboxMenuNoData = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    color: ${({ theme }) => theme.color.textGrayDairyDarker};
`;

const ITEM_HEIGHT = 40;
const MAX_HEIGHT = ITEM_HEIGHT * 7.5;

type Props<TValue extends ItemValue> = {
    items: ComboboxItemWithAllItem<TValue>[];
    onSelect: (item: ComboboxItemWithAllItem<TValue>) => void;
    options?: ComboboxOptions;
};

const ComboboxMenuRef = <TValue extends ItemValue>(props: Props<TValue>, ref: React.Ref<HTMLDivElement>) => {
    const { items, options } = props;

    const hasItems = items.length !== 0;
    const height = Math.min(items.length * ITEM_HEIGHT, MAX_HEIGHT);

    return (
        <ComboboxMenuStyled ref={ref} className={OUTSIDE_CLICK_NO_CLOSE_CLASS}>
            {!hasItems && <ComboboxMenuNoData>{options?.messageNoData ?? "No data..."}</ComboboxMenuNoData>}
            {hasItems && (
                <Virtuoso
                    style={{ height }}
                    totalCount={items.length}
                    itemContent={(index) => <ComboboxMenuItem item={items[index]} onItemSelect={props.onSelect} />}
                />
            )}
        </ComboboxMenuStyled>
    );
};

const ComboboxMenu = forwardRef(ComboboxMenuRef) as unknown as {
    <TValue extends ItemValue>(props: Props<TValue> & { ref?: React.Ref<HTMLDivElement> }): JSX.Element;
    displayName: string;
};
ComboboxMenu.displayName = "ComboboxMenu";

export { ComboboxMenu };
