import { RefCallback, useCallback, useState } from "react";
import useResizeObserver, { ObservedSize } from "use-resize-observer";

import { useScrollbarWidth } from "components/ui/table-final-saviour/Table/hooks/useScrollbarWidth";

const APPROXIMATE_ROW_HEIGHT = 32;
const APPROXIMATE_HEADER_ROW_HEIGHT = 34;

type Options = {
    approximateRowHeight?: number; // only change if the row height is different from the default (32px)
    maxHeight?: number;
    rowCount: number;
};
type Height = "100%" | number;
type ReturnValue = {
    refTable: RefCallback<HTMLTableElement>;
    refContainer: RefCallback<HTMLDivElement>;
    height: Height;
    // Should the scrollbar on the virtualized scroller component be hidden
    hideScrollbar?: boolean;
};

export const useAutoHeight = (options: Options): ReturnValue => {
    const { maxHeight, rowCount, approximateRowHeight = APPROXIMATE_ROW_HEIGHT } = options;

    const [{ height: observedTableHeightTable = 0, width: observedTableWidth = 0 }, setTableSize] =
        useState<ObservedSize>({
            width: 0,
            height: 0,
        });

    const onTableResize = useCallback((size: ObservedSize) => {
        requestAnimationFrame(() => setTableSize(size));
    }, []);
    const { ref: refTable } = useResizeObserver<HTMLTableElement>({
        // We need to wrap the callback in a requestAnimationFrame, otherwise the resize handler can be triggered
        // more times than the browser can handle, which can cause console errors (mainly on Chromium-based browsers)
        // https://github.com/ZeeCoder/use-resize-observer/issues/38
        onResize: onTableResize,
    });

    const [{ width: containerWidth = 0 }, setContainerSize] = useState<ObservedSize>({ width: 0, height: 0 });

    const onContainerResize = useCallback((size: ObservedSize) => {
        requestAnimationFrame(() => setContainerSize(size));
    }, []);
    const { ref: refContainer } = useResizeObserver<HTMLDivElement>({
        // We need to wrap the callback in a requestAnimationFrame, otherwise the resize handler can be triggered
        // more times than the browser can handle, which can cause console errors (mainly on Chromium-based browsers)
        // https://github.com/ZeeCoder/use-resize-observer/issues/38
        onResize: onContainerResize,
    });

    const scrollbarSize = useScrollbarWidth();

    // Approximate table height based on row count (should be always smaller than observedHeight, unless it is 0)
    // This is needed, otherwise the observedHeight can get stuck on 0 when the rows of the table are changed
    const approximatedHeight = approximateRowHeight * rowCount + APPROXIMATE_HEADER_ROW_HEIGHT;

    let height: Height;
    // Calculate the final height based on calculatedHeight and maxHeight, if provided
    if (maxHeight) {
        // Pick the larger of the two heights
        height = Math.max(approximatedHeight, observedTableHeightTable);
        // Set the height, but limit it to the maxHeight
        const extraHeight = observedTableWidth > containerWidth ? scrollbarSize : 0;
        height = Math.min(height + extraHeight, maxHeight);
    } else {
        // When no maxHeight is provided use "100%"
        height = "100%";
    }

    // When the observed height is smaller than the max height, letting the scroller component handle
    // the scrollbar visibility  can result in an infinite resize loop, which is caused by recomputing
    // the height upon showing/hiding the scrollbar.
    // It's clear that the scrollbar should be hidden in this case, so we can just explicitly set it here.

    let hideScrollbar: boolean;
    if (typeof height === "number") {
        // hide scrollbar if the observed height is smaller than the height
        hideScrollbar = observedTableHeightTable <= height;
    } else {
        // height is "100%"
        // hide scrollbar if the observed height is smaller than the maxHeight
        hideScrollbar = observedTableHeightTable <= (maxHeight ?? 0);
    }

    return { refTable, refContainer, height, hideScrollbar };
};
