import { createApi } from "@reduxjs/toolkit/query/react";

import { config } from "config";
import { createBaseQueryWithAuthRefresh, prepareHeaders } from "store/base-query";
import {
    AdministrationBusinessImprovement,
    AdministrationCluster,
    AdministrationIdentityProvider,
    AdministrationRole,
    AdministrationRoleCreateData,
    AdministrationRoleEditData,
    AdministrationUser,
    AdministrationUserList,
} from "store/services/administration/administration.service.types";

const TAG_ADMIN_APPLICATION_PERMISSION = "TAG_ADMIN_APPLICATION_PERMISSION";
const TAG_ADMIN_AREA = "TAG_ADMIN_AREA";
const TAG_ADMIN_BUSINESS_IMPROVEMENT = "TAG_ADMIN_BUSINESS_IMPROVEMENT";
const TAG_ADMIN_CLUSTER = "TAG_ADMIN_CLUSTER";
const TAG_ADMIN_DATA_PERMISSION = "TAG_ADMIN_DATA_PERMISSION";
const TAG_ADMIN_IDENTITY_PROVIDER = "TAG_ADMIN_IDENTITY_PROVIDER";
const TAG_ADMIN_NOTIFICATION = "TAG_ADMIN_NOTIFICATION";
const TAG_ADMIN_ROLE = "TAG_ADMIN_ROLE";
const TAG_ADMIN_USER = "TAG_ADMIN_USER";

// Split the API definition due to performance issues with Redux Toolkit
// https://github.com/reduxjs/redux-toolkit/issues/3214#issuecomment-1680462843
const emptyApi = createApi({
    reducerPath: "administrationAPI",
    baseQuery: createBaseQueryWithAuthRefresh({
        baseUrl: `${config.API_URL}api/`,
        prepareHeaders,
    }),
    tagTypes: [
        TAG_ADMIN_APPLICATION_PERMISSION,
        TAG_ADMIN_AREA,
        TAG_ADMIN_BUSINESS_IMPROVEMENT,
        TAG_ADMIN_CLUSTER,
        TAG_ADMIN_DATA_PERMISSION,
        TAG_ADMIN_IDENTITY_PROVIDER,
        TAG_ADMIN_NOTIFICATION,
        TAG_ADMIN_ROLE,
        TAG_ADMIN_USER,
    ],
    endpoints: () => ({}),
});

const generatedApi = emptyApi.injectEndpoints({
    endpoints: (builder) => ({
        // APPLICATION PERMISSION
        getApplicationPermissions: builder.query<any[], string>({
            query: (userId) => ({
                url: `admin/user/${userId}/app-permissions`,
            }),
            providesTags: () => [TAG_ADMIN_APPLICATION_PERMISSION],
        }),
        // AREA
        getAreas: builder.query<any[], void>({
            query: () => ({
                url: `admin/area`,
            }),
            providesTags: () => [TAG_ADMIN_AREA],
        }),
        // BUSINESS_IMPROVEMENT
        getBusinessImprovements: builder.query<AdministrationBusinessImprovement[], void>({
            query: () => ({
                url: `admin/businessImprovement`,
            }),
            providesTags: () => [TAG_ADMIN_BUSINESS_IMPROVEMENT],
        }),
        addBusinessImprovement: builder.mutation<void, any>({
            query: (body) => ({
                url: `admin/businessImprovement`,
                method: "POST",
                body,
            }),
            invalidatesTags: [TAG_ADMIN_BUSINESS_IMPROVEMENT],
        }),
        editBusinessImprovement: builder.mutation<void, { id: string; data: any }>({
            query: ({ id, data }) => ({
                url: `admin/businessImprovement/${id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: [TAG_ADMIN_BUSINESS_IMPROVEMENT],
        }),
        deleteBusinessImprovement: builder.mutation<void, string>({
            query: (id) => ({
                url: `admin/businessImprovement/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [TAG_ADMIN_BUSINESS_IMPROVEMENT],
        }),
        // CLUSTER
        getClusters: builder.query<AdministrationCluster[], void>({
            query: () => ({
                url: `admin/cluster`,
            }),
            providesTags: () => [TAG_ADMIN_CLUSTER],
        }),
        addCluster: builder.mutation<void, any>({
            query: (body) => ({
                url: `admin/cluster`,
                method: "POST",
                body,
            }),
            invalidatesTags: [TAG_ADMIN_CLUSTER],
        }),
        editCluster: builder.mutation<void, { id: string; data: any }>({
            query: ({ id, data }) => ({
                url: `admin/cluster/${id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: [TAG_ADMIN_CLUSTER],
        }),
        deleteCluster: builder.mutation<void, string>({
            query: (id) => ({
                url: `admin/cluster/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [TAG_ADMIN_CLUSTER],
        }),
        importClusters: builder.mutation<void, any>({
            query: (body) => ({
                url: `admin/ClusterImport`,
                method: "PUT",
                body,
            }),
            invalidatesTags: [TAG_ADMIN_CLUSTER],
        }),
        // DATA PERMISSION
        getDataPermissions: builder.query<any[], void>({
            query: () => ({
                // No idea where this ID comes from
                url: `admin/user/c2fd0dab-6a72-420e-8e7c-1b728e1e6008/data-permissions`,
            }),
            providesTags: () => [TAG_ADMIN_DATA_PERMISSION],
        }),
        // IDENTITY PROVIDER
        getIdentityProviders: builder.query<AdministrationIdentityProvider[], void>({
            query: () => ({
                url: `admin/Login/identityproviders`,
            }),
            providesTags: () => [TAG_ADMIN_IDENTITY_PROVIDER],
        }),
        // NOTIFICATION
        getNotifications: builder.query<any[], void>({
            query: () => ({
                url: `admin/notification`,
            }),
            providesTags: () => [TAG_ADMIN_NOTIFICATION],
        }),
        getNotificationRecipients: builder.query<any[], void>({
            query: () => ({
                url: `admin/recipient`,
            }),
        }),
        addNotification: builder.mutation<void, any>({
            query: (body) => ({
                url: `admin/notification`,
                method: "POST",
                body,
            }),
            invalidatesTags: [TAG_ADMIN_NOTIFICATION],
        }),
        editNotification: builder.mutation<void, { id: string; data: any }>({
            query: ({ id, data }) => ({
                url: `admin/notification/${id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: [TAG_ADMIN_NOTIFICATION],
        }),
        deleteNotification: builder.mutation<void, string>({
            query: (id) => ({
                url: `admin/notification/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [TAG_ADMIN_NOTIFICATION],
        }),
        // ROLE
        getRoles: builder.query<AdministrationRole[], void>({
            query: () => ({
                url: `admin/role`,
            }),
            providesTags: () => [TAG_ADMIN_ROLE],
        }),
        getRole: builder.query<AdministrationRole, string>({
            query: (id) => ({
                url: `admin/role/${id}`,
            }),
            providesTags: () => [TAG_ADMIN_ROLE],
        }),
        addRole: builder.mutation<void, AdministrationRoleCreateData>({
            query: (body) => ({
                url: `admin/role`,
                method: "POST",
                body,
            }),
            invalidatesTags: [TAG_ADMIN_ROLE],
        }),
        editRole: builder.mutation<void, AdministrationRoleEditData>({
            query: ({ id, data }) => ({
                url: `admin/role/${id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: [TAG_ADMIN_ROLE],
        }),
        deleteRole: builder.mutation<void, string>({
            query: (id) => ({
                url: `admin/role/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [TAG_ADMIN_ROLE],
        }),
        // USER
        getUsers: builder.query<AdministrationUserList[], void>({
            query: () => ({
                url: `admin/user`,
            }),
            providesTags: () => [TAG_ADMIN_USER],
        }),
        getUser: builder.query<AdministrationUser, string>({
            query: (id) => ({
                url: `admin/user/${id}`,
            }),
            providesTags: () => [TAG_ADMIN_USER],
        }),
        addUser: builder.mutation<void, any>({
            query: (body) => ({
                url: `admin/user`,
                method: "POST",
                body,
            }),
            invalidatesTags: [TAG_ADMIN_USER],
        }),
        editUser: builder.mutation<void, { id: string; data: any }>({
            query: ({ id, data }) => ({
                url: `admin/user/${id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: [TAG_ADMIN_USER],
        }),
        deleteUser: builder.mutation<void, string>({
            query: (id) => ({
                url: `admin/user/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [TAG_ADMIN_USER],
        }),
        addUserLogin: builder.mutation<void, any>({
            query: (body) => ({
                url: `admin/login`,
                method: "POST",
                body,
            }),
            invalidatesTags: [TAG_ADMIN_USER],
        }),
        editUserLogin: builder.mutation<void, { id: string; data: any }>({
            query: ({ id, data }) => ({
                url: `admin/login/${id}`,
                method: "PATCH",
                body: data,
            }),
            invalidatesTags: [TAG_ADMIN_USER],
        }),
        deleteUserLogin: builder.mutation<void, string>({
            query: (id) => ({
                url: `admin/login/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [TAG_ADMIN_USER],
        }),
    }),
});

export const api = generatedApi.enhanceEndpoints({}) as any as typeof generatedApi;

export { api as administrationApi };
