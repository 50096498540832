import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import styled from "styled-components";

import { ComboboxInput } from "components/ui/Combobox/ComboboxInput";
import { ComboboxMenu } from "components/ui/Combobox/ComboboxMenu";
import { ALL_ITEM_LABEL } from "components/ui/Combobox/combobox.consts";
import { useContentScroll } from "components/ui/Combobox/combobox.hooks";
import {
    ComboboxItem,
    ComboboxItemWithAllItem,
    ComboboxOptions,
    ItemValue,
} from "components/ui/Combobox/combobox.types";
import { search } from "components/ui/Combobox/combobox.utils";
import { Popover } from "components/ui/Popover";
import { Portal } from "components/ui-deprecated";
import { usePopover } from "hooks/usePopover";

const ComboboxStyled = styled.div`
    position: relative;

    width: 100%;
`;

type Props<TValue extends ItemValue> = {
    items: ComboboxItem<TValue>[];
    value?: TValue | null;
    onSelect: (item: ComboboxItem<TValue> | null) => void;
    className?: string;
    options?: ComboboxOptions;
    zIndex?: number;
};

const Combobox = <TValue extends ItemValue>(props: Props<TValue>) => {
    const { items: defaultItems, value, onSelect, options = {}, zIndex = 50 } = props;

    const displayNoneAsAll = options?.displayNoneAsAll ?? false;
    const allItemLabel = options.allItemLabel ?? ALL_ITEM_LABEL;

    const items = useMemo<ComboboxItemWithAllItem<TValue>[]>(() => {
        if (displayNoneAsAll) {
            // Adds an internal select item, automatically selected when input is empty
            return [...defaultItems, { value: null, label: allItemLabel }];
        } else {
            return defaultItems;
        }
    }, [defaultItems, displayNoneAsAll, allItemLabel]);

    const [inputValue, setInputValue] = useState<string>("");

    // Sync external 'inputValue' and select an item
    useEffect(() => {
        if (value === null) {
            // No selection
            setInputValue(displayNoneAsAll ? allItemLabel : "");
        } else {
            const foundItem = items.find((item) => item.value === value);
            setInputValue(foundItem ? foundItem.label : "");
        }
    }, [value, items, displayNoneAsAll, allItemLabel]);

    const [hasTyped, setHasTyped] = useState<boolean>(false);
    const filteredItems = hasTyped && inputValue ? search(items, inputValue) : items;

    const handleChange = (value: string) => {
        setInputValue(value);
        setHasTyped(true);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const isClickInsideMenu = menuRef.current?.contains(event.relatedTarget);
        if (isClickInsideMenu) return;

        const foundItem = items.find((item) => item.value === value);
        if (!foundItem) return;

        if (inputValue === foundItem.label) return;

        const firstItem = filteredItems.at(0) ?? null;
        onSelect(firstItem?.value === null ? null : (firstItem as ComboboxItem<TValue>));
    };

    const handleSelect = (item: ComboboxItemWithAllItem<TValue>) => {
        setInputValue(item.label);
        onSelect(item.value === null ? null : (item as ComboboxItem<TValue>));
        handleMenuClose();
        setHasTyped(false);
    };

    const handleResetValue = useCallback(() => {
        setInputValue(displayNoneAsAll ? allItemLabel : "");
        onSelect(null);
        setHasTyped(false);
    }, [displayNoneAsAll, allItemLabel, onSelect]);

    const menuRef = useRef<HTMLDivElement>(null);
    const {
        ref,
        isOpen: isMenuOpen,
        handleOpen: handleMenuOpen,
        handleToggleOpen: handleMenuToggle,
        handleClose: handleMenuClose,
    } = usePopover();

    useContentScroll(handleMenuClose);

    return (
        <ComboboxStyled ref={ref} className={props.className} onFocus={handleMenuOpen}>
            <ComboboxInput<TValue>
                comboboxValue={value || null}
                value={inputValue}
                onChange={handleChange}
                onBlur={handleBlur}
                isMenuOpen={isMenuOpen}
                onMenuToggleClick={handleMenuToggle}
                onReset={handleResetValue}
                options={options}
            />
            {isMenuOpen && (
                <Portal zIndex={zIndex}>
                    <Popover variant={"bottom"} $isPortal={true} $zIndex={zIndex}>
                        <ComboboxMenu<TValue>
                            ref={menuRef}
                            items={filteredItems}
                            onSelect={handleSelect}
                            options={options}
                        />
                    </Popover>
                </Portal>
            )}
        </ComboboxStyled>
    );
};

export { Combobox };
export type { Props as ComboboxProps };
