import { dayjs, Dayjs } from "components/ui/DatePicker/date.utils";

export type IntervalRange = "day" | "week" | "month";

const dayStepBoundary = 31;
const monthStepBoundary = 90;

export const getIntervalRange = (firstDate: Dayjs, lastDate: Dayjs): IntervalRange => {
    const daysBetween = lastDate.diff(firstDate, "days");
    if (daysBetween < dayStepBoundary) return "day";
    else if (daysBetween < monthStepBoundary) return "week";
    else return "month";
};

export const getWeeklyOrMonthlyIntervalRange = (firstDate: Dayjs, lastDate: Dayjs): IntervalRange => {
    const daysBetween = lastDate.diff(firstDate, "days");
    if (daysBetween < monthStepBoundary) return "week";
    else return "month";
};

export const getDateLabelByInterval = (date: string | number, intervalRange: IntervalRange): string => {
    const parsedDate = dayjs(date);
    switch (intervalRange) {
        case "day":
            return parsedDate.format("D MMM");
        case "week":
            return `${parsedDate.startOf("week").format("D MMM")} - ${parsedDate.endOf("week").format("D MMM")}`;
        case "month":
            return parsedDate.format("MMM");
    }
};

export const getAllDateIntervals = (from: Dayjs, to: Dayjs, intervalRange: IntervalRange): string[] => {
    const labels: string[] = [];
    let currentDate: Dayjs = from;

    const numberOfIntervals = to.diff(from, intervalRange) + 1;

    for (let i = 0; i < numberOfIntervals; i++) {
        labels.push(currentDate.format("YYYY-MM-DD"));
        currentDate = currentDate.add(1, intervalRange);
    }

    return labels;
};
