import { dayjs } from "components/ui/DatePicker/date.utils";

export const dateFormat = "YYYY-MM-DD";

export const yearAgo = dayjs().subtract(1, "year").startOf("day").format(dateFormat);
export const sixMonthsAgo = dayjs().subtract(6, "month").startOf("day").format(dateFormat);
export const monthAgo = dayjs().subtract(1, "month").startOf("day").format(dateFormat);
export const today = dayjs().startOf("day").format(dateFormat);

export type FormattedYearWeek = {
    short: string;
    long: string;
};

type DateToFormattedYearWeekOptions = {
    date: string | number;
    useISOWeek?: boolean;
};

export const dateToFormattedYearWeek = (options: DateToFormattedYearWeekOptions): FormattedYearWeek => {
    const { useISOWeek = false } = options;
    const parsedDate = dayjs(options.date);

    const LONG_DATE_FORMAT = "MMM Do";

    // for ISO weeks logic below doesn't apply
    if (useISOWeek) {
        const week = parsedDate.isoWeek();
        const weekStart = parsedDate.startOf("isoWeek").format(LONG_DATE_FORMAT);
        const weekEnd = parsedDate.endOf("isoWeek").format(LONG_DATE_FORMAT);
        const year = parsedDate.endOf("isoWeek").year();

        return {
            short: `W${week}, ${year}`,
            long: `W${week}: ${weekStart} - ${weekEnd}, ${year}`,
        };
    } else {
        const lastWeekNumberInYear = parsedDate.year();
        const week = isLastDayOfYear(options.date) ? lastWeekNumberInYear + 1 : parsedDate.week();
        const year = parsedDate.year();

        // since last week of year can be shorter, first week of year has to compensate for it
        const weekStart = parsedDate.startOf(week === 1 ? "year" : "week").format(LONG_DATE_FORMAT);

        /**
         * show last week of the year as shorter than 7 days if necessary
         * (e.g. if the last day of the year is a Monday, the week will be only 2 days long)
         */
        const isLastWeekInYear = week === lastWeekNumberInYear + 1;
        const weekEnd = parsedDate.endOf(isLastWeekInYear ? "year" : "week").format(LONG_DATE_FORMAT);

        return {
            short: `W${week}, ${year}`,
            long: `W${week}: ${weekStart} - ${weekEnd}, ${year}`,
        };
    }
};

/**
 * Converts date range into a week date range.
 * Basically it takes the `dateFrom` and `dateTo` and converts them to the first and last day of the week.
 * If `dateFrom` is in the current week or after, it will be converted to the first day of the week before the current week.
 * If `dateTo` is in the current week or after, it will be converted to the last day of the week before the current week.
 */
export const toWeekRangeDate = (
    dateRange: { dateFrom: string; dateTo: string },
    options?: { useISOWeek?: boolean }
) => {
    const { dateFrom, dateTo } = dateRange;

    const useISOWeek = options?.useISOWeek ?? false;
    const period = useISOWeek ? "isoWeek" : "week";
    const today = dayjs();

    let dateRangeFrom = dayjs(dateFrom);
    let dateRangeTo = dayjs(dateTo);

    const isIsoWeek = period === "isoWeek";
    const weekCurrent = isIsoWeek ? today.isoWeek() : today.week();
    const yearCurrent = today.year();

    const weekRangeDateFrom = isIsoWeek ? dateRangeFrom.isoWeek() : dateRangeFrom.week();
    const yearRangeDateFrom = dateRangeFrom.year();

    const weekRangeDateTo = isIsoWeek ? dateRangeTo.isoWeek() : dateRangeTo.week();
    const yearRangeDateTo = dateRangeTo.year();

    // Combine year and week into a single comparable value
    const currentWeekKey = yearCurrent * 100 + weekCurrent;
    const fromWeekKey = yearRangeDateFrom * 100 + weekRangeDateFrom;
    const toWeekKey = yearRangeDateTo * 100 + weekRangeDateTo;

    if (fromWeekKey >= currentWeekKey) {
        dateRangeFrom = today.clone().subtract(1, "week").startOf(period);
    } else {
        dateRangeFrom = dateRangeFrom.startOf(period);
    }

    if (toWeekKey >= currentWeekKey) {
        dateRangeTo = today.clone().subtract(1, "week").endOf(period);
    } else {
        dateRangeTo = dateRangeTo.endOf(period);
    }

    return {
        dateFrom: dateRangeFrom.format(dateFormat),
        dateTo: dateRangeTo.format(dateFormat),
    };
};

export const hoursToHoursAndMinutes = (hours: number) => {
    const hoursInt = Math.floor(hours);
    const minutes = Math.round((hours - hoursInt) * 60);

    if (minutes === 0) {
        return `${hoursInt}h`;
    }

    if (hoursInt === 0) {
        return `${minutes}m`;
    }

    return `${hoursInt}h ${minutes}m`;
};

// TODO: replace `getKPITimeScale` with `getTimeScale`
export type TimeScale = "week" | "month";
export const getTimeScale = (fromDate?: string | number, toDate?: string | number): TimeScale => {
    const from = dayjs(fromDate);
    const to = dayjs(toDate);

    return to.diff(from, "days") > 90 ? "month" : "week";
};

// only for non ISO weeks
export const getLastWeekNumberInYear = (year: number) => {
    const lastDayOfYear = dayjs().year(year).endOf("year");
    /**
     * if date is last day of the week, then it's the last week of the year,
     * otherwise it's the week before
     */
    const isLastDayOfWeek = lastDayOfYear.week() === 6;
    const weeksToSubtract = isLastDayOfWeek ? 0 : 1;

    return lastDayOfYear.subtract(weeksToSubtract, "week").week();
};

export const isLastDayOfYear = (date: string | number) => {
    const parsedDate = dayjs(date);

    return parsedDate.date() === 31 && parsedDate.month() === 11;
};

export const formatDuration = (secondsDuration: number, format: string) =>
    dayjs.utc(dayjs.duration(secondsDuration * 1000).asMilliseconds()).format(format);

export const getMinimalIsoDate = (isoDates: string[]): string | null => {
    if (isoDates.length === 0) return null;

    const dates = isoDates.map((date) => new Date(date));

    const minimalDate = dates.reduce(
        (minDate, dateToCompare) => (dateToCompare < minDate ? dateToCompare : minDate),
        dates[0]
    );
    return minimalDate.toISOString();
};
