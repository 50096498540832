import { useCallback } from "react";

import { dataSourceIdMap, DataSourceName, toDataSourceParameters } from "hooks/useDataSource";
import { dataSourceApi, DataSourceParameterValue, TAG_DATA_SOURCE } from "store/services/data-source.service";
import {
    dataSourceApi as dataSourceApiDeprecated,
    TAG_DATA_SOURCE as TAG_DATA_SOURCE_DEPRECATED,
} from "store/services/deprecated/data-source.service";
import { useAppDispatch } from "store/store.hooks";

type InvalidateCacheOptions = {
    query?: {
        name: DataSourceName;
        parameters: Record<string, DataSourceParameterValue>;
    };
};

const useInvalidateDataSourceCache = () => {
    const dispatch = useAppDispatch();

    /**
     * Invalidates the data source cache.
     *
     * @param options.query When provided, only the cache for the specified query will be invalidated.
     */
    const invalidateCache = useCallback(
        (options?: InvalidateCacheOptions) => {
            const { query } = options ?? {};

            let id: string | undefined;
            if (query) {
                const parameters = toDataSourceParameters(query.parameters);
                id = JSON.stringify({
                    id: dataSourceIdMap[query.name],
                    parameters,
                });
            }

            dispatch(dataSourceApi.util.invalidateTags([{ type: TAG_DATA_SOURCE, id }]));
            dispatch(dataSourceApiDeprecated.util.invalidateTags([{ type: TAG_DATA_SOURCE_DEPRECATED, id }]));
        },
        [dispatch]
    );

    return invalidateCache;
};

export { useInvalidateDataSourceCache };
