import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import {
    type ChartData,
    chartColor,
    createTimeXAxis,
    createLinearYAxis,
    createLineChartDataset,
    createLineChartConfiguration,
} from "components/ui/Chart";
import { createTooltipConfiguration } from "components/ui/Chart/configurations";
import { useDataSource } from "hooks/useDataSource";
import { useHasUserArea } from "hooks/useHasUserArea";
import { routes } from "routes";
import { generatePath } from "routes/routes.utils";

type Props = WidgetWithQueryParameters<"dateTo" | "dateFrom">;

type VectorAccuracyAndFreeTime = {
    ChartDate: string;
    ChartLabel: string;
    ChartValue: number;
};

// Source: FMSDashboard_VectorAccuracyAndFreeTime_VegaLiteWidget.json
// id: 29ad1dfa-4495-4068-8abf-22e4b5d5c1cf
const VectorLoadingAccuracyWidget = (props: Props) => {
    const { queryParameters } = props;
    const title = "Vector loading accuracy";

    const dataDataSource = useDataSource<VectorAccuracyAndFreeTime[]>(
        "FMS_Dashboard_VectorAccuracyAndFreeTime_KPIChart",
        {
            ...queryParameters,
        }
    );
    const mainDataSources = [dataDataSource];
    const data = dataDataSource?.data ?? [];

    const showVectorLoadingPerformanceLink = useHasUserArea(["FMS_VectorLoading"]);

    const chartConfiguration = createLineChartConfiguration({
        scales: {
            x: createTimeXAxis(),
            y: createLinearYAxis({ axisLabel: "Vector systems", beginAtZero: true }),
        },
        plugins: {
            tooltip: createTooltipConfiguration<any>({
                getLabel: ({ raw }) => `Accuracy: ${raw["ChartLabel"]}`,
            }),
        },
    });

    const chartData: ChartData = {
        labels: data.map(({ ChartDate }) => ChartDate),
        datasets: [
            createLineChartDataset({
                label: "Accuracy <95%",
                data: data.map(({ ChartDate, ChartValue, ChartLabel }) => ({
                    x: ChartDate,
                    y: ChartValue,
                    ChartLabel,
                })),
                color: chartColor.darkTeal,
            }),
        ],
    };

    return (
        <>
            <WidgetFrame.Title
                title={title}
                link={showVectorLoadingPerformanceLink ? generatePath(routes.vectorLoadingPerformance) : undefined}
                icon={"lely-icon-graph"}
            >
                <WidgetFrame.Title.Information lastImportType={"middleware"} pageId={1132855337} />
            </WidgetFrame.Title>
            <WidgetFrame.Chart configuration={chartConfiguration} data={chartData} dependencies={mainDataSources} />
        </>
    );
};

export { VectorLoadingAccuracyWidget };
