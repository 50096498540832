import { Dayjs } from "dayjs";
import styled from "styled-components";

import { PresetDateRange } from "components/ui/DatePicker/Presets/presets.types";
import { DateRangePicker } from "components/ui/DateRangePicker/DateRangePicker";
import { selectPanelCSS } from "components/widgets/selects/helpers/SelectPanel";

const DateRangePickerStyled = styled(DateRangePicker)`
    ${selectPanelCSS};
`;

type Props = {
    dateFrom: string;
    dateTo: string;
    onDateFromChange: (value: string) => void;
    onDateToChange: (value: string) => void;
    presets?: PresetDateRange[];
    labelFn?: (dateFrom: Dayjs, dateTo: Dayjs) => string;
};

const DateRangeWidget = (props: Props) => {
    const { dateFrom, dateTo } = props;

    return (
        <DateRangePickerStyled
            dateFrom={dateFrom}
            dateTo={dateTo}
            setDateFrom={props.onDateFromChange}
            setDateTo={props.onDateToChange}
            presets={props.presets}
            labelFn={props.labelFn}
        />
    );
};

export { DateRangeWidget };
