import {
    cs,
    da,
    de,
    enUS,
    es,
    et,
    fi,
    fr,
    hu,
    it,
    ja,
    ko,
    lt,
    lv,
    nl,
    nb,
    pl,
    pt,
    ru,
    sv,
    tr,
    zhCN,
} from "date-fns/locale";

export enum Languages {
    cs = "Česky",
    da = "Dansk",
    de = "Deutsch",
    en = "English",
    es = "Español",
    et = "Eesti",
    fi = "Suomi",
    fr = "Français",
    hu = "Magyar",
    it = "Italiano",
    ja = "日本語",
    ko = "한국어",
    lt = "Lietuvių",
    lv = "Latvių",
    nl = "Nederlands",
    no = "Norsk",
    pl = "Polski",
    pt = "Português",
    tr = "Türkçe",
    ru = "русский",
    sv = "Svenska",
    zh = "汉语",
}

export type Language = keyof typeof Languages;

export const localeDateFNSMap = {
    cs,
    da,
    de,
    en: enUS,
    es,
    et,
    fi,
    fr,
    hu,
    it,
    ja,
    ko,
    lt,
    lv,
    nl,
    no: nb,
    pl,
    pt,
    ru,
    sv,
    zh: zhCN,
    tr,
} satisfies Record<Language, Locale>;

const languageKeys = (Object.keys(Languages) as Language[]).toSorted((a, b) =>
    Languages[a].localeCompare(Languages[b])
);

export const fmsLanguages: Language[] = languageKeys.filter((lang) => {
    const ignored: Language[] = ["tr"];
    return !ignored.includes(lang);
});

export const careCheckLanguages: Language[] = languageKeys.filter((lang) => {
    const ignored: Language[] = ["zh", "ru", "no"];
    return !ignored.includes(lang);
});
