import { useState } from "react";

import { FeedbackModal } from "components/layout/Feedback/FeedbackModal";
import { FeedbackStyled } from "components/layout/Feedback/feedback.styled-components";
import { Dialog } from "components/ui-deprecated/Dialog";

const Feedback = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    return (
        <div>
            <FeedbackStyled onClick={() => setShow((x) => !x)}>Feedback</FeedbackStyled>
            <Dialog open={show} onOpenChange={handleClose}>
                <FeedbackModal onClose={handleClose} />
            </Dialog>
        </div>
    );
};

export { Feedback };
