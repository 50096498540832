import clsx from "clsx";

import styles from "components/ui-deprecated/Dialog/Dialog.module.css";

type Props = {
    children: React.ReactNode;
    className?: string;
};

const DialogContentTitle = (props: Props) => {
    return <div className={clsx(styles.DialogContentTitle, props.className)}>{props.children}</div>;
};

export { DialogContentTitle };
