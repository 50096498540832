import * as Yup from "yup";

import { Form, SelectField } from "components/ui-deprecated";
import { Button } from "components/ui-deprecated/Button";
import { Dialog } from "components/ui-deprecated/Dialog";
import { useGetImpersonationUsersQuery, UserImpersonation } from "store/services/user.service";

const validationSchema = Yup.object({
    userId: Yup.string().required(),
});

const initialValues = { userId: "" };

type Props = {
    onSubmit: (user: UserImpersonation) => Promise<void>;
    onCancel: () => void;
    disableSubmit: boolean;
};

function ImpersonationForm(props: Props) {
    const { data: users } = useGetImpersonationUsersQuery();

    const userOptions = (users ?? []).map((x) => ({ text: x.name, value: x.userId }));

    const handleSubmit = async (data: { userId: string }) => {
        const { userId } = data;
        const user = users?.find((x) => x.userId === userId);

        if (!user) {
            throw new Error(`User with id "${userId}" doesn't exist.`);
        }

        await props.onSubmit(user);
    };

    return (
        <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            <Dialog.Content.Body>
                <SelectField
                    label={"User"}
                    name={"userId"}
                    options={userOptions}
                    placeholder={"Select user you want to impersonate as"}
                />
            </Dialog.Content.Body>
            <Dialog.Content.Actions>
                <Button variant={"text"} type={"button"} onClick={props.onCancel}>
                    Cancel
                </Button>
                <Button type={"submit"} disabled={props.disableSubmit}>
                    Submit
                </Button>
            </Dialog.Content.Actions>
        </Form>
    );
}

export { ImpersonationForm };
