import { DialogContentActions, DialogContentBody } from "@datamole/wds-component-dialog";
import { Trigger } from "@radix-ui/react-dialog";

import { DialogContent } from "components/ui-deprecated/Dialog/DialogContent/DialogContent";
import { DialogContentTitle } from "components/ui-deprecated/Dialog/DialogContent/DialogContentTitle";
import { Root } from "components/ui-deprecated/Dialog/Root";

const Dialog = Object.assign(Root, {
    Content: Object.assign(DialogContent, {
        Title: DialogContentTitle,
        Body: DialogContentBody,
        Actions: DialogContentActions,
    }),
    Trigger: Trigger,
});

export { Dialog, DialogContent, DialogContentTitle, DialogContentBody, DialogContentActions };
export type { DialogWidth } from "components/ui-deprecated/Dialog/DialogContent/DialogContent";
