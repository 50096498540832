import { apiJS as baseApi } from "store/api";

export const TAG_DATA_SOURCE = "DATA_SOURCE";

export type DataSourceParameterValue = string | number | boolean;
export type DataSourceParameter = { name: string; value: DataSourceParameterValue };

const api = baseApi
    .enhanceEndpoints({
        addTagTypes: [TAG_DATA_SOURCE],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getDataSource: builder.query<any, { id: string; parameters?: DataSourceParameter[] }>({
                query: ({ id, parameters }) => ({
                    url: `data-source/${id}${
                        parameters ? `?parameters=${encodeURIComponent(JSON.stringify(parameters))}` : ""
                    }`,
                    method: "GET",
                }),
                providesTags: (_result, _error, arg) => [{ type: TAG_DATA_SOURCE, id: JSON.stringify(arg) }],
            }),
        }),
    });

export { api as dataSourceApi };
export const { useGetDataSourceQuery, useLazyGetDataSourceQuery } = api;
