import { ComboboxItem, ComboboxItemWithAllItem, ItemValue } from "components/ui/Combobox/combobox.types";
import { DataWrapper, map, Row } from "deprecated/data-wrapper";

export const search = <TValue extends ItemValue>(
    items: ComboboxItemWithAllItem<TValue>[],
    inputValue: string | null
): ComboboxItemWithAllItem<TValue>[] => {
    if (inputValue == null || inputValue === "") return items;

    const inputValueNormalized = inputValue.toLocaleLowerCase();
    return items.filter((item) => {
        return ["value", "label"].some((key) => {
            const val = item[key as keyof ComboboxItem<TValue>];
            return String(val).toLocaleLowerCase().includes(inputValueNormalized);
        });
    });
};

export const getRowLabel = (row: Row, displayField: string | string[]): string => {
    return Array.isArray(displayField)
        ? displayField.map((key) => String(row[key])).join(" - ")
        : String(row[displayField]);
};

export const toItemsFromArray = <TRow, TValue extends ItemValue>(
    data: TRow[] | undefined,
    options: {
        getValue: (row: TRow) => TValue;
        getLabel: (row: TRow) => string;
        sort?: (a: TRow, b: TRow) => number;
    }
): ComboboxItem<TValue>[] => {
    if (!data) return [];

    return data
        .toSorted((a, b) => {
            if (options.sort) {
                return options.sort(a, b);
            }

            const labelA = options.getLabel(a);
            const labelB = options.getLabel(b);

            return labelA.localeCompare(labelB);
        })
        .map((row) => ({
            value: options.getValue(row),
            label: options.getLabel(row),
        }));
};

export const toItemsFromDataWrapper = <TValue extends ItemValue>(
    data: DataWrapper | null,
    valueField: string,
    displayField: string | string[]
): ComboboxItem<TValue>[] | null => {
    if (!data) return null;

    return map(data, (row) => ({
        // Assuming row[valueField] is always string or number
        value: row[valueField] as TValue,
        label: getRowLabel(row, displayField),
    })).toSorted((a, b) => a.label.localeCompare(b.label));
};
