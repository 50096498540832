import { useMemo } from "react";

/**
 * It calculates scrollbar width only once.
 * To observe changes in scrollbar width, implement debouncing or add one of packages.
 * Example package: https://github.com/shawnmcknight/react-scrollbar-size
 */
export const useScrollbarWidth = () => {
    const scrollbarWidth = useMemo(() => {
        // create temporary element with scroll
        const element = document.createElement("div");
        element.style.width = "99px";
        element.style.height = "99px";
        element.style.overflow = "scroll";
        element.style.position = "absolute";
        element.style.top = "-9999px";
        element.setAttribute("aria-hidden", "true");
        element.setAttribute("role", "presentation");

        // add element to the body
        document.body.appendChild(element);
        const { offsetWidth, clientWidth } = element;
        const width = offsetWidth - clientWidth;

        // remove element from the body
        document.body.removeChild(element);

        return width;
    }, []);

    return scrollbarWidth;
};
