import { z } from "zod";

import { dataPermissionSchema } from "schemas/data-permission.schema";

export const userAreaNameSchema = z.enum([
    "Agreements",
    "AGV_AlarmOverview",
    "Chameleon",
    "ENG_ConnectionPerformance",
    "ENG_LinerAdvice",
    "ENG_MilkFlowProfile",
    "FMS_Beta",
    "FMS_Core",
    "FMS_Dev",
    "FMS_CareCheck",
    "FMS_VectorLoading",
    "LIOBS_Core",
    "Refactor_JSBEDataSources",
    "Refactor_DataSourcesNewApproach",
    "ServiceManager_Core",
    "TDS_Liner_PhaseTwoWidgets",
    "TSS_Core",
    "TSS_MandatoryUpdates",
    "TSS_Parts",
    "TSS_PreventiveMaintenanceOverdue",
    "TSS_RepairsPerAstronaut",
    "TSS_RepeatVisitCases",
    "TSS_Technician_Core",
    "TSS_TechnicianDetail",
    "TSS_TeamLead_Core",
    "TSS_TeamLeadKPI",
]);

export const userAreaSchema = z.object({
    id: z.string().uuid(),
    name: z.string(),
    displayName: z.string(),
});

export const userClusterSchema = z.object({
    id: z.string().uuid(),
    name: z.string(),
});

export const userPermissionSchema = z.object({
    canAdministrationRead: z.boolean().optional(),
    canAdministrationWrite: z.boolean().optional(),
    canImpersonate: z.boolean().optional(),
    isApplicationManager: z.boolean().optional(),
    canAdministerInsights: z.boolean().optional(),
    canFilterClusters: z.boolean().optional(),
});

export const userRoleTypeSchema = z.enum(["primary", "secondary"]);

const userRoleLeafSchema = z.object({
    id: z.string().uuid(),
    type: userRoleTypeSchema,
    name: z.string(),
    areas: z.array(userAreaSchema),
    mainRoleId: z.string().uuid().nullable(),
});

export const userRoleSchema = userRoleLeafSchema.extend({
    children: z.array(userRoleLeafSchema),
});

export const userSettingLanguageSchema = z.object({ name: z.literal("language"), value: z.string() });
export const userSettingUnitsSchema = z.object({ name: z.literal("units"), value: z.enum(["Metric", "Imperial"]) });
export const userSettingPrimaryActiveRoleIdSchema = z.object({
    name: z.literal("primaryActiveRoleId"),
    value: z.string(),
});
export const userSettingSecondaryActiveRolesIdsSchema = z.object({
    name: z.literal("secondaryActiveRolesIds"),
    value: z.string(),
});

export const userSettingSchema = z.union([
    userSettingLanguageSchema,
    userSettingUnitsSchema,
    userSettingPrimaryActiveRoleIdSchema,
    userSettingSecondaryActiveRolesIdsSchema,
    // Catch-all for any other undefined setting
    // z.object({ name: z.string(), value: z.any() }),
]);

export const userSchema = z
    .object({
        id: z.string().uuid(),
        name: z.string(),
        login: z.string().optional(),
        cluster: z.string().optional(),
        company: z.string().optional(),
        selectedClusters: z.array(userClusterSchema),
        dataPermissions: z.array(dataPermissionSchema).optional(),
        parameters: userPermissionSchema,
        permissions: userPermissionSchema,
        roles: z.array(userRoleSchema),
        userSettings: z.array(userSettingSchema),
    })
    // Allow any other unspecified fields
    .passthrough();
