import { Alert } from "@datamole/wds-component-alert";
import { Button } from "@datamole/wds-component-button";
import { useState } from "react";

import { Icon } from "components/ui-deprecated";
import { Dialog } from "components/ui-deprecated/Dialog";
import { InsightsWidgetExpandedDataRow } from "pages/DashboardPage/tss/widgets/InsightsWidget/insights-widget.types";
import { useAppSelector } from "store";
import { useInvalidateDataSourceCache } from "store/hooks/useInvalidateDataSourceCache";
import { useDeleteInsightMutation } from "store/services/insights.service";

type Props = {
    data: InsightsWidgetExpandedDataRow;
};

const DeleteInsightButton = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const userId = useAppSelector((state) => state.user.id);
    const invalidateDataSourceCache = useInvalidateDataSourceCache();

    const [deleteInsight] = useDeleteInsightMutation();

    const handleSubmit = async () => {
        await deleteInsight({
            insightId: props.data.InsightId,
            insightCreatedDate: props.data.InsightCreatedDate,
            useful: false,
            userId,
        });
        setIsOpen(false);
        invalidateDataSourceCache({
            query: {
                name: "Insights_ExpandedDetail",
                parameters: {
                    customerNumber: props.data.CustomerNumber,
                },
            },
        });
    };

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <Dialog.Trigger asChild>
                <Button onClick={() => {}} size={"sm"}>
                    <Button.Icon>
                        <Icon icon={"trash-alt"} />
                    </Button.Icon>
                    <Button.Content>Delete</Button.Content>
                </Button>
            </Dialog.Trigger>
            <Dialog.Content>
                <Dialog.Content.Title>Delete Insight?</Dialog.Content.Title>
                <Dialog.Content.Body>
                    <Alert severity={"warning"}>
                        <Alert.Icon />
                        Are you sure you want to delete this insight? This action cannot be undone.
                    </Alert>
                </Dialog.Content.Body>
                <Dialog.Content.Actions>
                    <Button onClick={() => setIsOpen(false)}>
                        <Button.Icon>
                            <Icon icon={"cross"} />
                        </Button.Icon>
                        <Button.Content>No, cancel</Button.Content>
                    </Button>
                    <Button onClick={handleSubmit} variant={"primary"}>
                        <Button.Icon>
                            <Icon icon={"check"} />
                        </Button.Icon>
                        <Button.Content>Yes, delete</Button.Content>
                    </Button>
                </Dialog.Content.Actions>
            </Dialog.Content>
        </Dialog>
    );
};

export { DeleteInsightButton };
