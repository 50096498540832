import { PresetDateRange } from "components/ui/DatePicker/Presets/presets.types";
import {
    today,
    startOfYearDay,
    weekAgo,
    monthAgo,
    isTodayLastDayOfMonth,
    Dayjs,
} from "components/ui/DatePicker/date.utils";

const last14MonthsTo = isTodayLastDayOfMonth ? today : monthAgo.endOf("month");

export const presets: PresetDateRange[] = [
    { title: "Last 7 Days", value: { from: today.subtract(7, "day"), to: today } },
    { title: "Last 30 Days", value: { from: today.subtract(30, "day"), to: today } },
    { title: "Last 365 Days", value: { from: today.subtract(365, "day"), to: today } },
    { title: "Last Week", value: { from: weekAgo.startOf("week"), to: weekAgo.endOf("week") } },
    { title: "Last Month", value: { from: monthAgo.startOf("month"), to: monthAgo.endOf("month") } },
    {
        title: "Last 14 Months",
        value: {
            from: last14MonthsTo.subtract(13, "month").startOf("month"),
            to: last14MonthsTo,
        },
    },
    { title: "This Year", value: { from: startOfYearDay, to: today } },
    {
        title: "Last Year",
        value: {
            from: today.subtract(1, "year").startOf("year"),
            to: today.subtract(1, "year").endOf("year"),
        },
    },
];

export const createDefaultLabel = (dateFrom: Dayjs, dateTo: Dayjs) => {
    const dateFromString = dateFrom.format("MMM Do");
    const dateToString = dateTo.format("MMM Do, YYYY");
    const dateFromYearString = dateFrom.format("YYYY");
    const dateToYearString = dateTo.format("YYYY");
    const dateFromOptionalYearString = dateFromYearString !== dateToYearString ? `, ${dateFromYearString}` : "";

    return `${dateFromString}${dateFromOptionalYearString} - ${dateToString}`;
};
