// @ts-strict-ignore
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import ExpandableRow from "components/components-deprecated/tables/components/ExpandableRow";
import ExpandableRowWithInnerLayout from "components/components-deprecated/tables/components/ExpandableRowWithInnerLayout";
import ExpandableStaticRow from "components/components-deprecated/tables/components/ExpandableStaticRow";
import StandardRow from "components/components-deprecated/tables/components/StandardRow";
import { HiddenColumns } from "components/components-deprecated/tables/data-source-query.interfaces";
import { Row } from "components/components-deprecated/tables/tables.interfaces";
import { notification } from "components/ui-deprecated";
import { fields } from "deprecated/data-wrapper";
import { DataWrapper } from "deprecated/data-wrapper/data-wrapper.interfaces";
import { setProperty } from "store";

export const getCellContent = (
    row,
    field,
    formatter,
    fieldsToLinks,
    customColumnTypes = {},
    combineColumns = {},
    setContextFromColumns = {}
) => {
    // FIXME
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation();
    const content = formatter(row[field], field);

    if (
        fieldsToLinks &&
        field in fieldsToLinks &&
        row[fieldsToLinks[field]] !== null &&
        row[fieldsToLinks[field]] !== undefined
    ) {
        if (String(formatter(row[fieldsToLinks[field]], fieldsToLinks[field])).indexOf("http") > -1) {
            return (
                <a
                    href={formatter(row[fieldsToLinks[field]], fieldsToLinks[field])}
                    target={"_blank"}
                    rel={"noreferrer"}
                >
                    {t(content.toString())}
                </a>
            );
        } else if (String(content).trim().length === 0) {
            return "-";
        } else if (formatter(row[fieldsToLinks[field]], fieldsToLinks[field]).indexOf("/null") === -1) {
            return <Link to={formatter(row[fieldsToLinks[field]], fieldsToLinks[field])}>{t(content)}</Link>;
        }
    } else if (Object.keys(customColumnTypes).includes(field)) {
        return getCustomComponent(customColumnTypes[field], t(content), row);
    } else if (Object.keys(setContextFromColumns).includes(field)) {
        return getCustomComponent("SetContextValue", t(content), row, {
            valueContextKey: setContextFromColumns[field],
            value: content,
        });
    } else if (Object.keys(combineColumns).includes(field)) {
        return getCombinedColumn(combineColumns[field], row);
    } else {
        return t(content);
    }
};

const getCombinedColumn = (combineColumns: Array<string>, row) => {
    let content = "";
    combineColumns.forEach((colName) => {
        if (row[colName] !== undefined && row[colName] !== null) {
            content = content + ` ${row[colName]}`;
        }
    });
    return content;
};

const getCustomComponent = (
    customType: string,
    content: any,
    row: Row,
    contextKeyUpdate: {
        valueContextKey;
        value;
    } = { valueContextKey: null, value: null }
): JSX.Element => {
    // FIXME
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();
    const PROGRESS_BAR_TOOLTIP_VALUE =
        row["PercDoneAtDueDate"] !== undefined ? `Done by Due Date: ${row["PercDoneAtDueDate"]}%` : null;

    switch (customType) {
        case "SetContextValue":
            return (
                <div
                    className={"status-modal-link"}
                    onClick={(e) => {
                        notification.info("Filters loading, please wait...");
                        e.stopPropagation();
                        // To send the notification first
                        setTimeout(() => {
                            if (contextKeyUpdate.valueContextKey !== null && contextKeyUpdate.value !== null) {
                                dispatch(
                                    setProperty({
                                        key: contextKeyUpdate.valueContextKey,
                                        value: contextKeyUpdate.value,
                                    })
                                );
                            }
                        }, 1000);
                    }}
                >
                    {content}
                </div>
            );

        case "TipForDevelopmentDropdown":
            return (
                <div>TEST</div>
                // <TipForDevelopmentDropdown firstRow={rows[0]} currentRow={row} field={field} />
            );

        default:
            return content;
    }
};

export const getDataRow = (
    row,
    headers,
    formatter,
    fieldsToLinks,
    expandable,
    expandableQueryParameters,
    expandableStaticData,
    customColumnTypes = {},
    setContextFromColumns = {},
    doubleExpandable,
    widerColumns,
    innerTableLayout
) => {
    if (expandable && expandableQueryParameters) {
        if (innerTableLayout !== null && innerTableLayout !== undefined) {
            return (
                <ExpandableRowWithInnerLayout
                    key={JSON.stringify(row)}
                    expandableQueryParameters={expandableQueryParameters}
                    row={row}
                    level={0}
                    headers={headers}
                    widerColumns={widerColumns}
                    innerTableLayout={innerTableLayout}
                />
            );
        } else {
            return (
                <ExpandableRow
                    key={JSON.stringify(row)}
                    level={0}
                    expandableQueryParameters={expandableQueryParameters}
                    row={row}
                    headers={headers}
                    doubleExpandable={doubleExpandable}
                    widerColumns={widerColumns}
                />
            );
        }
    } else if (expandable && expandableStaticData) {
        return (
            <ExpandableStaticRow
                key={JSON.stringify(row)}
                expandableStaticData={expandableStaticData}
                row={row}
                headers={headers}
                widerColumns={widerColumns}
            />
        );
    } else {
        return (
            <StandardRow
                key={JSON.stringify(row)}
                row={row}
                headers={headers}
                formatter={formatter}
                fieldsToLinks={fieldsToLinks}
                customColumnTypes={customColumnTypes}
                setContextFromColumns={setContextFromColumns}
                widerColumns={widerColumns}
            />
        );
    }
};

export const getHeadersForLevel = (
    data: DataWrapper,
    hiddenColumns: HiddenColumns,
    level: number,
    grouping = false,
    addHeaders = []
) => {
    const headers = fields(data).filter((value) => hiddenColumns[`level${level}`].indexOf(value) === -1);
    if (grouping) {
        // Assuming that the first column is the "Group" to group by, remove it from headers
        headers.shift();
    }

    addHeaders.forEach((header) => {
        headers.push(header);
    });
    return headers;
};

export const removeDuplicates = (array: Array<any>): Array<string> => {
    return array.filter((value, index) => array.indexOf(value) === index);
};
