// @ts-strict-ignore
import { useEffect, useRef, useState } from "react";

import CSVExport from "components/components-deprecated/CSVExport";
import { BooleanFilterItem } from "components/components-deprecated/tables/BooleanFilters";
import TableSearch from "components/components-deprecated/tables/TableSearch";
import MultiSelectFilter from "components/components-deprecated/tables/components/MultiSelectFilter";
import { removeDuplicates } from "components/components-deprecated/tables/tables.helper";
import { Combobox, toItemsFromDataWrapper } from "components/ui/Combobox";
import { Flex } from "components/ui-deprecated";
import { fromJS, map } from "deprecated/data-wrapper";
import { DataWrapper } from "deprecated/data-wrapper/data-wrapper.interfaces";
import { useAppSelector, initialState as defaultWidgetContext } from "store";

type BooleanFilters = { [key: string]: boolean };
type Filters = Array<string>;

export interface FilterProps {
    data: DataWrapper;
    filteredData: DataWrapper;
    search: boolean;
    setCurrentPage: (number) => void;
    filterColumns: Array<string>;
    commaSplitFilterColumns: Array<string>;
    multiSelectFilterColumns: Array<string>;
    contextFilters: { [key: string]: string };
    booleanFilters: BooleanFilters;
    setBooleanFilters: (BooleanFilters) => void;
    searchTerm: string;
    setSearchTerm: (string) => void;
    filters: Filters;
    setFilters: (Filters) => void;
    cumulativeFilters: Filters;
    setCumulativeFilters: (Filters) => void;
    csvExportTable: boolean;
}

const Filters: (props: FilterProps) => JSX.Element = (props: FilterProps) => {
    const {
        data,
        filteredData,
        search,
        setCurrentPage,
        filterColumns,
        commaSplitFilterColumns,
        multiSelectFilterColumns,
        contextFilters,
        booleanFilters,
        setBooleanFilters,
        searchTerm,
        setSearchTerm,
        filters,
        setFilters,
        cumulativeFilters,
        setCumulativeFilters,
        csvExportTable,
    } = props;

    const filteredRows = map(filteredData, (r) => r) || [];

    const updateFilters = (i: number, val: string) => {
        setFilters((prev) => {
            const n = [...prev];
            n[i] = val;
            return n;
        });
    };

    const widgetReduxState = useAppSelector((state) => state.widget);
    const [previousReduxState, setPreviousReduxState] = useState({});

    const scrollRef = useRef(null);
    const executeScroll = () => scrollRef.current.scrollIntoView({ behavior: "smooth" });

    const mergedFilters = [...filterColumns];
    commaSplitFilterColumns.forEach((column) => {
        if (!multiSelectFilterColumns.includes(column)) {
            mergedFilters.push(column);
        }
    });

    const hasFilters =
        search ||
        Object.keys(booleanFilters).length > 0 ||
        mergedFilters.length > 0 ||
        multiSelectFilterColumns.length > 0;

    useEffect(() => {
        const mergedAndMulti = [...mergedFilters, ...multiSelectFilterColumns];
        // Set context filters when context updates
        mergedAndMulti.forEach((item, i) => {
            const contextItem = widgetReduxState[contextFilters[item]];
            const previousContextItem = previousReduxState[contextFilters[item]];
            if (item in contextFilters && contextItem !== undefined && contextItem !== previousContextItem) {
                // Scroll only if it's been updated to something
                if (contextItem !== defaultWidgetContext[contextFilters[item]]) {
                    executeScroll();
                }
                setCurrentPage(0);
                if (multiSelectFilterColumns.includes(item)) {
                    setCumulativeFilters([contextItem]);
                } else {
                    updateFilters(i, contextItem);
                }
            }
        });

        setPreviousReduxState(widgetReduxState);
        // Disable exhaustive deps check, because we don't want to re-render on change to previousReduxState
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgetReduxState]);

    return hasFilters ? (
        <Flex className={"basic-table-filters"} justifyContent={"flex-end"}>
            {search && (
                <TableSearch
                    value={searchTerm}
                    setValue={(p: string) => {
                        setSearchTerm(p);
                        setCurrentPage(0);
                    }}
                />
            )}
            {(mergedFilters.length > 0 || multiSelectFilterColumns.length > 0) && (
                <Flex
                    className={"filters"}
                    justifyContent={"flex-end"}
                    alignItems={"stretch"}
                    style={{ flex: "1 1 auto" }}
                >
                    <span style={{ color: "#333", margin: "0 0.75rem", alignSelf: "center" }}>
                        {/*{filters.length === 1 ? "Filter: " : "Filters: "}*/}
                    </span>

                    {mergedFilters.map((item, i) => {
                        const filterData = commaSplitFilterColumns.includes(item)
                            ? getCommaSplitFilterData(data, item)
                            : getFilterData(data, item);
                        const items = toItemsFromDataWrapper<string>(filterData, item, item) ?? [];

                        return (
                            <div
                                key={`basic-filter-${i}`}
                                className={"lssa20-select-wrapper"}
                                ref={item in contextFilters ? scrollRef : null}
                            >
                                <Combobox<string>
                                    items={items}
                                    value={filters[i] || null}
                                    onSelect={(item) => {
                                        setCurrentPage(0);
                                        updateFilters(i, item?.value ?? "");
                                    }}
                                    options={{
                                        placeholder: `Select ${item}`,
                                        displayNoneAsAll: true,
                                    }}
                                />
                            </div>
                        );
                    })}
                    {multiSelectFilterColumns.map((item, i) => {
                        const msfData = commaSplitFilterColumns.includes(item)
                            ? getCommaSplitFilterData(data, item)
                            : getFilterData(data, item);

                        // const allOptions = map(msfData, (row) => row[item]);

                        return (
                            <div
                                key={`multi-filter-${i}`}
                                className={"lssa20-select-wrapper"}
                                ref={item in contextFilters ? scrollRef : null}
                            >
                                <MultiSelectFilter
                                    data={msfData}
                                    valueField={item}
                                    selected={cumulativeFilters}
                                    setSelected={(selectedItems) => {
                                        setCurrentPage(0);
                                        setCumulativeFilters(selectedItems);
                                    }}
                                    placeholderText={`Select ${item}s`}
                                />
                            </div>
                        );
                    })}
                </Flex>
            )}
            {Object.keys(booleanFilters).length > 0 && (
                <Flex justifyContent={"flex-end"} alignItems={"stretch"}>
                    {Object.keys(booleanFilters).map((item, i) => (
                        <BooleanFilterItem
                            key={i}
                            index={i}
                            booleanFilters={booleanFilters}
                            item={item}
                            setBooleanFilters={setBooleanFilters}
                            setIndexOfExpandedContent={() => null}
                        />
                    ))}
                </Flex>
            )}
            {csvExportTable && (
                <Flex justifyContent={"center"} alignItems={"center"}>
                    <div className={"table-csv-export"}>
                        <CSVExport data={filteredRows} text={"Export Table to CSV"} />
                    </div>
                </Flex>
            )}
        </Flex>
    ) : null;
};

export default Filters;

function getFilterData(data: DataWrapper, item: string): null | DataWrapper {
    const dataForFilter = fromJS(
        [...Array.from(new Set(map(data, (r) => r[item])))]
            .filter((f) => f !== null)
            .sort((a, b) => (String(a) > String(b) ? 1 : -1))
            .map((r) => ({ [item]: r }))
    );
    if (dataForFilter instanceof Error) {
        return null;
    } else {
        return dataForFilter;
    }
}

function getCommaSplitFilterData(data: DataWrapper, item: string): null | DataWrapper {
    let splitData = [];
    map(data, (r) => {
        return splitData.push(...r[item].split(", "));
    });
    splitData = removeDuplicates(splitData).map((r) => ({ [item]: r }));
    splitData = splitData.sort((a, b) => a[item].localeCompare(b[item]));

    const dataForFilter = fromJS(splitData);

    if (dataForFilter instanceof Error) {
        return null;
    } else {
        return dataForFilter;
    }
}
