import { Root as RadixDialogRoot, type DialogProps } from "@radix-ui/react-dialog";

/**
 * Note: The Radix Dialog Root component is used by both new and old Dialog component.
 * So the wrapper is needed to not override the new Dialog Root component.
 */
const Root = (props: DialogProps) => <RadixDialogRoot {...props} />;

export { Root };
export type { DialogProps };
